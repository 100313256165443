import type { } from 'redux-thunk/extend-redux';
import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { combineReducers, Action, Middleware } from 'redux';
import { NQValueKind, isNqError } from '@visonum/network-quality-sdk';
import measurementReducer, { complete, updatePrepareResult } from './features/measurement/measurementSlice';
import hints, { updateHintIds, updateNqRecordHints } from "./features/hints/hintsSlice"
import errorReducer, { reportNQError, resetError } from "./features/errors/errorSlice"
import * as history from "./features/history/historySlice"
import * as pageManager from "./features/pageManager/pageManagerSlice"
import { isRunningInBrowser } from './helper/ssr';
import { showCustomerCommunicationAfterMeasurement } from './features/customerCommunication/showCustomerCommunicationAfterMeasurement';
import { NODE_ENV, VARIANT } from './config';
import { isProductionMode, logger } from './helper/logger';
import * as R from 'ramda';
import debugLogSlice, { initDebugLog } from './features/debugLog/debugLogSlice';
import privacyPolicySlice from './features/privacyPolicy/privacyPolicySlice';
import sentryReportsSlice, { initSentryReports, updateBeforeUnloadCaused } from './features/sentryReports/sentryReportsSlice';
import matomoSlice, { initMatomo } from './features/matomo/matomoSlice';
import { createNQRecord } from './features/history/createNQRecord';
import focusManagerSlice, { closeDialog, showDialog } from './features/focusManager/focusManagerSlice';
import { getNboObject } from './features/nextBestOffer/NextBestOffer';
import nextBestOfferSlice, { hideNboTeaser, showNextBestOfferAfterMeasurement } from './features/nextBestOffer/nextBestOfferSlice';
import ViewDialogKey from './features/focusManager/ViewDialogKey';
import { updateHistoryRecordIndex } from './features/focusManager/resultScreenHelper';
import { ErrorItemKey } from './features/focusManager/FocusabeItemKey';
import pageManagerSlice, { updatePage } from './features/pageManager/pageManagerSlice';
import { addListeners } from './browserEvents';
import consentSlice, { initConsent } from './features/consent/consentSlice';
import { PageKey } from './features/pageManager/pageState';
import { MeasurementState, Phase } from './features/measurement/types';
import dialogManagerSlice, { DialogKind, hideDialog, showErrorDialog } from './features/dialogManager/dialogManagerSlice';
import { partialServiceSendAnalytics } from './features/partialService/partialServiceUtils';
import { NQRecord } from './features/history/types';
import { sortChronologically } from './helper/utils';
import { plainDate } from './types';
import { updateResultPageTab } from "./features/pageManager/pageManagerSlice";
import { ResultTabKey } from "./features/pageManager/pageState";


const isNqValue = (value: any): boolean => {
  return !R.isNil(value?.kind) && value.kind in NQValueKind;
}

/**
 * Filters out the member methods from NQValue and NQError objects during serialization
 */
const getEntries = (value: any): [string, any][] => {
  const result = Object.entries(value)
  if (isNqValue(value) || isNqError(value)) {
    return result.filter(([k, v]) => typeof v !== 'function')
  }
  return result
}

const completeMeasurementMiddleware: Middleware<{}, RootState> = storeApi => next => action => {
  if (complete.match(action)) {
    // Cancellation
    if (storeApi.getState().dialogManager.kind == DialogKind.SpeedtestCancellation) {
      storeApi.dispatch(hideDialog());
    }

    //Customer Communication
    if (VARIANT == "SPLUS") {
      storeApi.dispatch(showCustomerCommunicationAfterMeasurement());
    }

    storeApi.dispatch(updateResultPageTab(ResultTabKey.CurrentMesasurement));

    storeApi.dispatch(updatePage(PageKey.Result));
  }
  next(action);
}

const nextBestOfferMiddleware: Middleware<{}, RootState> = storeApi => next => action => {
  if (complete.match(action)) {
    storeApi.dispatch(showNextBestOfferAfterMeasurement());
  }
  if (updatePage.match(action)) {
    if (action.payload !== PageKey.Result) {
      storeApi.dispatch(hideNboTeaser());
    }
  }
  next(action);
}

const gigaTvErrorMiddleware: Middleware<{}, RootState> = storeApi => next => action => {
  if (VARIANT === 'TV') {

    if (reportNQError.match(action)) {
      storeApi.dispatch(showDialog({ dialogKey: ViewDialogKey.Error, state: { selectedItem: ErrorItemKey.CloseButton } }));
    } else if (resetError.match(action)) {
      const state = storeApi.getState();
      if (state.focusManager.dialog?.dialogKey === ViewDialogKey.Error) {
        storeApi.dispatch(closeDialog());
      }
    }
  }

  next(action);
}

const historyMiddleware: Middleware<{}, RootState> = storeApi => next => action => {
  next(action);

  if (VARIANT === 'TV') {
    if (history.updateRecords.match(action)) {
      storeApi.dispatch(updateHistoryRecordIndex());
    }
  }
}

const errorMiddleware: Middleware<{}, RootState> = storeApi => next => action => {
  if (reportNQError.match(action)) {
    if (action.payload.scheme == "Sm-1") {
    } else {
      storeApi.dispatch(showErrorDialog(action.payload));
    }
  } else if (resetError.match(action)) {
    storeApi.dispatch(hideDialog());
  }

  next(action);
}

const prepareResultMiddleware: Middleware<{}, RootState> = storeApi => next => action => {
  if (updatePrepareResult.match(action)) {
    storeApi.dispatch(partialServiceSendAnalytics(action.payload.init.speedtest.id));
  }

  next(action);
}

const rootReducer = combineReducers({
  measurement: measurementReducer,
  history: history.reducer,
  hints: hints.reducer,
  error: errorReducer,
  debugLog: debugLogSlice.reducer,
  privacyPolicy: privacyPolicySlice.reducer,
  sentryReports: sentryReportsSlice.reducer,
  matomo: matomoSlice.reducer,
  focusManager: focusManagerSlice.reducer,
  nextBestOffer: nextBestOfferSlice.reducer,
  pageManager: pageManagerSlice.reducer,
  consent: consentSlice.reducer,
  dialogManager: dialogManagerSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    [
      ...getDefaultMiddleware(
        {
          serializableCheck: {
            getEntries,
            // Need to use Date object which is causing non-serializable error
            ignoredActions: ['pageManager/setLastSurveyDate'],
            ignoredPaths: [
              // Need to use Date object which is causing non-serializable error
              "pageManager.resultState.lastSurveyDate",
              // loadResult is native PureScript internal data structure. Serialization would be performance overhead.
              "measurement.loadResult",
            ],
          },
        }
      ),
      completeMeasurementMiddleware,
      nextBestOfferMiddleware,
      gigaTvErrorMiddleware,
      historyMiddleware,
      errorMiddleware,
      prepareResultMiddleware
    ],
    devTools: !isProductionMode()
});

const setupStore = async () => {
  store.dispatch(history.initHistory());
  store.dispatch(pageManager.initPageManager());


  if (isRunningInBrowser()) {
    addListeners();

    if (NODE_ENV === "test") {
      logger.info("Set store to window object", { store });
      window.storeTest = {
        store,
        sentryReportActions: {
          updateBeforeUnloadCaused
        },
        historyActions: {
          updateRecords: history.updateRecords,
          updateValues: (measurement: MeasurementState) => {
            const nqRecord = createNQRecord(plainDate(), measurement, null);
            if (nqRecord !== null) {
              const newItems = [...store.getState().history.records, nqRecord];
              store.dispatch(history.updateStore(newItems));
            }
          },
          initHistory: history.initHistory,
          deleteAllRecords: history.deleteAllRecords,
          deleteRecordBySpeedtestId: history.deleteRecordBySpeedtestId,
          injectSyntheticData: (records: NQRecord[]) => {
            store.dispatch(history.updateRecords(records));
            if (records.length > 0) {
              const lastRecord = sortChronologically(records)[0]!;
              if (lastRecord.hints != null) {
                store.dispatch(updateHintIds(lastRecord.hints));
              }
            }
          }
        },
        getNboObject,
      }
    } else {
      logger.info("NOT set store to window object.")
    }

    store.dispatch(initConsent());
    store.dispatch(initDebugLog());
    await store.dispatch(updateNqRecordHints());
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(1);
      }, 5000);
    });

    store.dispatch(initSentryReports());
    store.dispatch(initMatomo());
  }
}

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupStore();